// This class is autogenerated. Do NOT modify
class configurationConstants {
    static backup = {
        localRootPath: "Backup.LocalRootPath",
        tempPath: "Backup.TempPath",
        allowedDestinations: "Backup.AllowedDestinations",
        allowedAwsRegions: "Backup.AllowedAwsRegions",
        maxNumberOfConcurrentBackups: "Backup.MaxNumberOfConcurrentBackups",
        concurrentBackupsDelay: "Backup.ConcurrentBackupsDelayInSec",
        lowMemoryBackupDelay: "Backup.LowMemoryBackupDelayInMin",
        cloudStorageOperationTimeout: "Backup.CloudStorageOperationTimeoutInMin",
        azureLegacy: "Backup.Azure.Legacy",
        compressionAlgorithm: "Backup.Compression.Algorithm",
        compressionLevel: "Backup.Compression.Level",
        snapshotCompressionAlgorithm: "Backup.Snapshot.Compression.Algorithm",
        snapshotCompressionLevel: "Backup.Snapshot.Compression.Level",
        moveToNewResponsibleNodeGracePeriod: "Backup.MoveToNewResponsibleNodeGracePeriodInMin"
    }
    static cluster = {
        electionTimeout: "Cluster.ElectionTimeoutInMs",
        workerSamplePeriod: "Cluster.WorkerSamplePeriodInMs",
        supervisorSamplePeriod: "Cluster.SupervisorSamplePeriodInMs",
        receiveFromWorkerTimeout: "Cluster.ReceiveFromWorkerTimeoutInMs",
        onErrorDelayTime: "Cluster.OnErrorDelayTimeInMs",
        operationTimeout: "Cluster.OperationTimeoutInSec",
        stabilizationTime: "Cluster.StatsStabilizationTimeInSec",
        addReplicaTimeout: "Cluster.TimeBeforeAddingReplicaInSec",
        moveToRehabGraceTime: "Cluster.TimeBeforeMovingToRehabInSec",
        rotatePreferredNodeGraceTime: "Cluster.TimeBeforeRotatingPreferredNodeInSec",
        tcpConnectionTimeout: "Cluster.TcpTimeoutInMs",
        tcpSendBufferSize: "Cluster.TcpSendBufferSizeInBytes",
        tcpReceiveBufferSize: "Cluster.TcpReceiveBufferSizeInBytes",
        hardDeleteOnReplacement: "Cluster.HardDeleteOnReplacement",
        maximalAllowedClusterVersion: "Cluster.MaximalAllowedClusterVersion",
        compareExchangeTombstonesCleanupInterval: "Cluster.CompareExchangeTombstonesCleanupIntervalInMin",
        maxClusterTransactionCompareExchangeTombstoneCheckInterval: "Cluster.MaxClusterTransactionCompareExchangeTombstoneCheckIntervalInMin",
        logHistoryMaxEntries: "Cluster.LogHistoryMaxEntries",
        compareExchangeExpiredCleanupInterval: "Cluster.CompareExchangeExpiredDeleteFrequencyInSec",
        maxChangeVectorDistance: "Cluster.MaxChangeVectorDistance",
        disableAtomicDocumentWrites: "Cluster.DisableAtomicDocumentWrites",
        maxSizeOfSingleRaftCommand: "Cluster.MaxSizeOfSingleRaftCommandInMb",
        maxClusterTransactionsBatchSize: "Cluster.MaxClusterTransactionBatchSize"
    }
    static core = {
        serverUrls: "ServerUrl",
        tcpServerUrls: "ServerUrl.Tcp",
        clusterServerUrl: "ServerUrl.Cluster",
        clusterPublicTcpServerUrl: "PublicServerUrl.Tcp.Cluster",
        externalPublicTcpServerUrl: "PublicServerUrl.Tcp.External",
        publicServerUrl: "PublicServerUrl",
        publicTcpServerUrl: "PublicServerUrl.Tcp",
        externalIp: "ExternalIp",
        runInMemory: "RunInMemory",
        dataDirectory: "DataDir",
        enforceDataDirectoryPath: "DataDir.EnforcePath",
        setupMode: "Setup.Mode",
        setupResultingServerCertificatePath: "Setup.Certificate.Path",
        acmeUrl: "AcmeUrl",
        throwIfAnyIndexCannotBeOpened: "ThrowIfAnyIndexCannotBeOpened",
        featuresAvailability: "Features.Availability",
        echoSocketPort: "Testing.EchoSocket.Port"
    }
    static database = {
        queryTimeout: "Databases.QueryTimeoutInSec",
        queryOperationTimeout: "Databases.QueryOperationTimeoutInSec",
        operationTimeout: "Databases.OperationTimeoutInSec",
        collectionOperationTimeout: "Databases.CollectionOperationTimeoutInSec",
        compressRevisionsDefault: "Databases.Compression.CompressRevisionsDefault",
        compressAllCollectionsDefault: "Databases.Compression.CompressAllCollectionsDefault",
        concurrentLoadTimeout: "Databases.ConcurrentLoadTimeoutInSec",
        maxConcurrentLoads: "Databases.MaxConcurrentLoads",
        maxIdleTime: "Databases.MaxIdleTimeInSec",
        frequencyToCheckForIdle: "Databases.FrequencyToCheckForIdleInSec",
        pulseReadTransactionLimit: "Databases.PulseReadTransactionLimitInMb",
        deepCleanupThreshold: "Databases.DeepCleanupThresholdInMin",
        regularCleanupThreshold: "Databases.RegularCleanupThresholdInMin"
    }
    static debug = {
        eventListenerMode: "Debug.EventListener.Mode",
        eventTypes: "Debug.EventListener.EventTypes",
        minimumDuration: "Debug.EventListener.MinimumDurationInMs",
        allocationsLoggingInterval: "Debug.EventListener.AllocationsLoggingIntervalInMs",
        allocationsLoggingCount: "Debug.EventListener.AllocationsLoggingCount"
    }
    static embedded = {
        parentProcessId: "Testing.ParentProcessId"
    }
    static etl = {
        sqlCommandTimeout: "ETL.SQL.CommandTimeoutInSec",
        extractAndTransformTimeout: "ETL.ExtractAndTransformTimeoutInSec",
        maxNumberOfExtractedDocuments: "ETL.MaxNumberOfExtractedDocuments",
        maxNumberOfExtractedItems: "ETL.MaxNumberOfExtractedItems",
        maxFallbackTime: "ETL.MaxFallbackTimeInSec",
        maxBatchSize: "ETL.MaxBatchSizeInMb",
        olapMaxNumberOfExtractedDocuments: "ETL.OLAP.MaxNumberOfExtractedDocuments",
        kafkaInitTransactionsTimeout: "ETL.Queue.Kafka.InitTransactionsTimeoutInSec",
        azureQueueStorageTimeToLive: "ETL.Queue.AzureQueueStorage.TimeToLiveInSec",
        azureQueueStorageVisibilityTimeout: "ETL.Queue.AzureQueueStorage.VisibilityTimeoutInSec"
    }
    static exportImport = {
        compressionAlgorithm: "Export.Compression.Algorithm",
        compressionLevel: "Export.Compression.Level"
    }
    static http = {
        minDataRatePerSecond: "Http.MinDataRateBytesPerSec",
        minDataRateGracePeriod: "Http.MinDataRateGracePeriodInSec",
        maxRequestBufferSize: "Http.MaxRequestBufferSizeInKb",
        maxRequestLineSize: "Http.MaxRequestLineSizeInKb",
        keepAlivePingTimeout: "Http.Http2.KeepAlivePingTimeoutInSec",
        keepAlivePingDelay: "Http.Http2.KeepAlivePingDelayInSec",
        maxStreamsPerConnection: "Http.Http2.MaxStreamsPerConnection",
        useResponseCompression: "Http.UseResponseCompression",
        allowResponseCompressionOverHttps: "Http.AllowResponseCompressionOverHttps",
        gzipResponseCompressionLevel: "Http.GzipResponseCompressionLevel",
        deflateResponseCompressionLevel: "Http.DeflateResponseCompressionLevel",
        zstdResponseCompressionLevel: "Http.ZstdResponseCompressionLevel",
        staticFilesResponseCompressionLevel: "Http.StaticFilesResponseCompressionLevel",
        protocols: "Http.Protocols",
        allowSynchronousIo: "Http.AllowSynchronousIO"
    }
    static indexing = {
        runInMemory: "Indexing.RunInMemory",
        disabled: "Indexing.Disable",
        staticIndexDeploymentMode: "Indexing.Static.DeploymentMode",
        autoIndexDeploymentMode: "Indexing.Auto.DeploymentMode",
        autoIndexArchivedDataProcessingBehavior: "Indexing.Auto.ArchivedDataProcessingBehavior",
        staticIndexArchivedDataProcessingBehavior: "Indexing.Static.ArchivedDataProcessingBehavior",
        enableMetrics: "Indexing.Metrics.Enabled",
        tempPath: "Indexing.TempPath",
        maxTimeForDocumentTransactionToRemainOpen: "Indexing.MaxTimeForDocumentTransactionToRemainOpenInSec",
        timeBeforeDeletionOfSupersededAutoIndex: "Indexing.TimeBeforeDeletionOfSupersededAutoIndexInSec",
        timeToWaitBeforeMarkingAutoIndexAsIdle: "Indexing.TimeToWaitBeforeMarkingAutoIndexAsIdleInMin",
        disableQueryOptimizerGeneratedIndexes: "Indexing.DisableQueryOptimizerGeneratedIndexes",
        timeToWaitBeforeDeletingAutoIndexMarkedAsIdle: "Indexing.TimeToWaitBeforeDeletingAutoIndexMarkedAsIdleInHrs",
        minNumberOfMapAttemptsAfterWhichBatchWillBeCanceledIfRunningLowOnMemory: "Indexing.MinNumberOfMapAttemptsAfterWhichBatchWillBeCanceledIfRunningLowOnMemory",
        numberOfConcurrentStoppedBatchesIfRunningLowOnMemory: "Indexing.NumberOfConcurrentStoppedBatchesIfRunningLowOnMemory",
        mapTimeout: "Indexing.MapTimeoutInSec",
        queryClauseCacheSize: "Indexing.QueryClauseCache.SizeInMb",
        queryClauseCacheDisabled: "Indexing.QueryClauseCache.Disabled",
        queryClauseCacheExpirationScanFrequency: "Indexing.QueryClauseCache.ExpirationScanFrequencyInSec",
        queryClauseCacheRepeatedQueriesCount: "Indexing.QueryClauseCache.RepeatedQueriesCount",
        queryClauseCacheRepeatedQueriesTimeFrame: "Indexing.QueryClauseCache.RepeatedQueriesTimeFrameInSec",
        mapBatchSize: "Indexing.MapBatchSize",
        mapTimeoutAfterEtagReached: "Indexing.MapTimeoutAfterEtagReachedInMin",
        maxStepsForScript: "Indexing.MaxStepsForScript",
        allowStringCompilation: "Indexing.AllowStringCompilation",
        cleanupInterval: "Indexing.CleanupIntervalInMin",
        minGram: "Indexing.Analyzers.NGram.MinGram",
        maxGram: "Indexing.Analyzers.NGram.MaxGram",
        managedAllocationsBatchLimit: "Indexing.ManagedAllocationsBatchSizeLimitInMb",
        maximumSizePerSegment: "Indexing.MaximumSizePerSegmentInMb",
        mergeFactor: "Indexing.MergeFactor",
        largeSegmentSizeToMerge: "Indexing.LargeSegmentSizeToMergeInMb",
        numberOfLargeSegmentsToMergeInSingleBatch: "Indexing.NumberOfLargeSegmentsToMergeInSingleBatch",
        maxTimeForMergesToKeepRunning: "Indexing.MaxTimeForMergesToKeepRunningInSec",
        transactionSizeLimit: "Indexing.TransactionSizeLimitInMb",
        encryptedTransactionSizeLimit: "Indexing.Encrypted.TransactionSizeLimitInMb",
        scratchSpaceLimit: "Indexing.ScratchSpaceLimitInMb",
        globalScratchSpaceLimit: "Indexing.GlobalScratchSpaceLimitInMb",
        maxTimeToWaitAfterFlushAndSyncWhenExceedingScratchSpaceLimit: "Indexing.MaxTimeToWaitAfterFlushAndSyncWhenExceedingScratchSpaceLimit",
        indexMissingFieldsAsNull: "Indexing.IndexMissingFieldsAsNull",
        indexEmptyEntries: "Indexing.IndexEmptyEntries",
        errorIndexStartupBehavior: "Indexing.ErrorIndexStartupBehavior",
        indexStartupBehavior: "Indexing.IndexStartupBehavior",
        maxNumberOfConcurrentlyRunningIndexes: "Indexing.MaxNumberOfConcurrentlyRunningIndexes",
        nuGetPackagesPath: "Indexing.NuGetPackagesPath",
        nuGetPackageSourceUrl: "Indexing.NuGetPackageSourceUrl",
        nuGetAllowPreleasePackages: "Indexing.NuGetAllowPreleasePackages",
        historyRevisionsNumber: "Indexing.History.NumberOfRevisions",
        defaultAnalyzer: "Indexing.Analyzers.Default",
        defaultExactAnalyzer: "Indexing.Analyzers.Exact.Default",
        defaultSearchAnalyzer: "Indexing.Analyzers.Search.Default",
        throttlingTimeInterval: "Indexing.Throttling.TimeIntervalInMs",
        autoIndexingEngineType: "Indexing.Auto.SearchEngineType",
        staticIndexingEngineType: "Indexing.Static.SearchEngineType",
        documentsLimitForCompressionDictionaryCreation: "Indexing.Corax.DocumentsLimitForCompressionDictionaryCreation",
        skipDatabaseIdValidationOnIndexOpening: "Indexing.SkipDatabaseIdValidationOnIndexOpening",
        timeSinceLastQueryAfterWhichDeepCleanupCanBeExecuted: "Indexing.TimeSinceLastQueryAfterWhichDeepCleanupCanBeExecutedInMin",
        requireAdminToDeployJavaScriptIndexes: "Indexing.Static.RequireAdminToDeployJavaScriptIndexes",
        orderByScoreAutomaticallyWhenBoostingIsInvolved: "Indexing.OrderByScoreAutomaticallyWhenBoostingIsInvolved",
        luceneUseCompoundFileInMerging: "Indexing.UseCompoundFileInMerging",
        luceneIndexInput: "Indexing.Lucene.IndexInputType",
        maxTimeToWaitAfterFlushAndSyncWhenReplacingSideBySideIndex: "Indexing.MaxTimeToWaitAfterFlushAndSyncWhenReplacingSideBySideIndexInSec",
        minimumTotalSizeOfJournalsToRunFlushAndSyncWhenReplacingSideBySideIndex: "Indexing.MinimumTotalSizeOfJournalsToRunFlushAndSyncWhenReplacingSideBySideIndexInMb",
        orderByTicksAutomaticallyWhenDatesAreInvolved: "Indexing.OrderByTicksAutomaticallyWhenDatesAreInvolved",
        readerTermsIndexDivisor: "Indexing.Lucene.ReaderTermsIndexDivisor",
        coraxIncludeDocumentScore: "Indexing.Corax.IncludeDocumentScore",
        coraxIncludeSpatialDistance: "Indexing.Corax.IncludeSpatialDistance",
        maxMemoizationSize: "Indexing.Corax.MaxMemoizationSizeInMb",
        maxAllocationsAtDictionaryTraining: "Indexing.Corax.MaxAllocationsAtDictionaryTrainingInMb",
        resetMode: "Indexing.ResetMode",
        coraxStaticIndexComplexFieldIndexingBehavior: "Indexing.Corax.Static.ComplexFieldIndexingBehavior"
    }
    static license = {
        license: "License",
        licensePath: "License.Path",
        eulaAccepted: "License.Eula.Accepted",
        canActivate: "License.CanActivate",
        canForceUpdate: "License.CanForceUpdate",
        canRenew: "License.CanRenewLicense",
        skipLeasingErrorsLogging: "License.SkipLeasingErrorsLogging",
        disableAutoUpdate: "License.DisableAutoUpdate",
        disableAutoUpdateFromApi: "License.DisableAutoUpdateFromApi",
        disableLicenseSupportCheck: "License.DisableLicenseSupportCheck",
        throwOnInvalidOrMissingLicense: "License.ThrowOnInvalidOrMissingLicense"
    }
    static logs = {
        path: "Logs.Path",
        mode: "Logs.Mode",
        useUtcTime: "Logs.UseUtcTime",
        maxFileSize: "Logs.MaxFileSizeInMb",
        retentionTime: "Logs.RetentionTimeInHrs",
        retentionSize: "Logs.RetentionSizeInMb",
        compress: "Logs.Compress",
        disableMicrosoftLogs: "Logs.Microsoft.Disable",
        microsoftLogsConfigurationPath: "Logs.Microsoft.ConfigurationPath"
    }
    static memory = {
        lowMemoryLimit: "Memory.LowMemoryLimitInMb",
        lowMemoryCommitLimit: "Memory.LowMemoryCommitLimitInMb",
        maxContextSizeToKeep: "Memory.MaxContextSizeToKeepInMb",
        minimumFreeCommittedMemoryPercentage: "Memory.MinimumFreeCommittedMemoryPercentage",
        maxFreeCommittedMemoryToKeep: "Memory.MaxFreeCommittedMemoryToKeepInMb",
        useTotalDirtyMemInsteadOfMemUsage: "Memory.UseTotalDirtyMemInsteadOfMemUsage",
        enableHighTemporaryDirtyMemoryUse: "Memory.EnableHighTemporaryDirtyMemoryUse",
        temporaryDirtyMemoryAllowedPercentage: "Memory.TemporaryDirtyMemoryAllowedPercentage",
        temporaryDirtyMemoryChecksPeriod: "Memory.TemporaryDirtyMemoryChecksPeriodInSec",
        largeObjectHeapCompactionThresholdPercentage: "Memory.GC.LargeObjectHeapCompactionThresholdPercentage"
    }
    static migration = {
        migratorPath: "Migration.MigratorPath"
    }
    static monitoring = {
        cpuUsageMonitorExec: "Monitoring.Cpu.Exec",
        cpuUsageMonitorExecArguments: "Monitoring.Cpu.Exec.Arguments",
        minDiskStatsInterval: "Monitoring.Disk.ReadStatsDebounceTimeInMs"
    }
    static notifications = {
        filteredOutNotifications: "Notifications.FilterOut"
    }
    static patching = {
        maxStepsForScript: "Patching.MaxStepsForScript",
        allowStringCompilation: "Patching.AllowStringCompilation",
        strictMode: "Patching.StrictMode"
    }
    static performanceHints = {
        hugeDocumentSize: "PerformanceHints.Documents.HugeDocumentSizeInMb",
        hugeDocumentsCollectionSize: "PerformanceHints.Documents.HugeDocumentsCollectionSize",
        maxWarnIndexOutputsPerDocument: "PerformanceHints.Indexing.MaxIndexOutputsPerDocument",
        maxNumberOfResults: "PerformanceHints.MaxNumberOfResults",
        tooLongRequestThreshold: "PerformanceHints.TooLongRequestThresholdInSec",
        minSwapSize: "PerformanceHints.Memory.MinSwapSizeInMb",
        maxNumberOfLoadsPerReference: "PerformanceHints.Indexing.MaxNumberOfLoadsPerReference",
        alertWhenSourceDocumentIncludedInOutput: "PerformanceHints.Indexing.AlertWhenSourceDocumentIncludedInOutput",
        maxDepthOfRecursionInLinqSelect: "PerformanceHints.Indexing.MaxDepthOfRecursionInLinqSelect"
    }
    static query = {
        maxClauseCount: "Query.MaxClauseCount",
        regexTimeout: "Query.RegexTimeoutInMs"
    }
    static queueSink = {
        maxBatchSize: "QueueSink.MaxBatchSize",
        maxFallbackTime: "QueueSink.MaxFallbackTimeInSec"
    }
    static replication = {
        activeConnectionTimeout: "Replication.ActiveConnectionTimeoutInSec",
        replicationMinimalHeartbeat: "Replication.ReplicationMinimalHeartbeatInSec",
        retryReplicateAfter: "Replication.RetryReplicateAfterInSec",
        retryMaxTimeout: "Replication.RetryMaxTimeoutInSec",
        maxItemsCount: "Replication.MaxItemsCount",
        maxSizeToSend: "Replication.MaxSizeToSendInMb",
        numberOfEnumeratedDocumentsToCheckIfPulseLimitExceeded: "Replication.NumberOfEnumeratedDocumentsToCheckIfPulseLimitExceeded",
        maxNumberOfAttachmentsTrackedForDeduplication: "Replication.MaxNumberOfAttachmentsTrackedForDeduplication"
    }
    static security = {
        disableHttpsRedirection: "Security.DisableHttpsRedirection",
        disableHsts: "Security.DisableHsts",
        auditLogPath: "Security.AuditLog.FolderPath",
        auditLogRetentionTime: "Security.AuditLog.RetentionTimeInHrs",
        auditLogRetentionSize: "Security.AuditLog.RetentionSizeInMb",
        auditLogCompress: "Security.AuditLog.Compress",
        certificatePath: "Security.Certificate.Path",
        doNotConsiderMemoryLockFailureAsCatastrophicError: "Security.DoNotConsiderMemoryLockFailureAsCatastrophicError",
        certificatePassword: "Security.Certificate.Password",
        certificateExec: "Security.Certificate.Exec",
        certificateLoadExec: "Security.Certificate.Load.Exec",
        certificateRenewExec: "Security.Certificate.Renew.Exec",
        certificateChangeExec: "Security.Certificate.Change.Exec",
        certificateLoadExecArguments: "Security.Certificate.Load.Exec.Arguments",
        certificateRenewExecArguments: "Security.Certificate.Renew.Exec.Arguments",
        certificateChangeExecArguments: "Security.Certificate.Change.Exec.Arguments",
        certificateExecTimeout: "Security.Certificate.Exec.TimeoutInSec",
        certificateLetsEncryptEmail: "Security.Certificate.LetsEncrypt.Email",
        masterKeyPath: "Security.MasterKey.Path",
        masterKeyExec: "Security.MasterKey.Exec",
        masterKeyExecArguments: "Security.MasterKey.Exec.Arguments",
        masterKeyExecTimeout: "Security.MasterKey.Exec.TimeoutInSec",
        unsecuredAccessAllowed: "Security.UnsecuredAccessAllowed",
        wellKnownAdminCertificates: "Security.WellKnownCertificates.Admin",
        wellKnownIssuers: "Security.WellKnownIssuers.Admin",
        wellKnownIssuerHashes: "Security.WellKnownIssuerHashes.Admin",
        certificateValidationExec: "Security.Certificate.Validation.Exec",
        certificateValidationExecArguments: "Security.Certificate.Validation.Exec.Arguments",
        certificateValidationExecTimeout: "Security.Certificate.Validation.Exec.TimeoutInSec",
        tlsCipherSuites: "Security.TlsCipherSuites",
        certificateValidationKeyUsages: "Security.Certificate.Validation.KeyUsages",
        enableCsrfFilter: "Security.Csrf.Enabled",
        csrfTrustedOrigins: "Security.Csrf.TrustedOrigins",
        csrfAdditionalOriginHeaders: "Security.Csrf.AdditionalOriginHeaders",
        defaultTwoFactorSessionDuration: "Security.TwoFactor.DefaultSessionDurationInMin",
        maxTwoFactorSessionDuration: "Security.TwoFactor.MaxSessionDurationInMin",
        certificateExpiringThreshold: "Security.Certificate.ExpiringThresholdInDays"
    }
    static server = {
        maxTimeForTaskToWaitForDatabaseToLoad: "Server.MaxTimeForTaskToWaitForDatabaseToLoadInSec",
        name: "Server.Name",
        processAffinityMask: "Server.ProcessAffinityMask",
        indexingAffinityMask: "Server.IndexingAffinityMask",
        numberOfUnusedCoresByIndexes: "Server.NumberOfUnusedCoresByIndexes",
        cpuCreditsBase: "Server.CpuCredits.Base",
        cpuCreditsMax: "Server.CpuCredits.Max",
        cpuCreditsExhaustionBackgroundTasksThreshold: "Server.CpuCredits.ExhaustionBackgroundTasksThreshold",
        cpuCreditsExhaustionFailoverThreshold: "Server.CpuCredits.ExhaustionFailoverThreshold",
        cpuCreditsExhaustionBackupDelay: "Server.CpuCredits.ExhaustionBackupDelayInMin",
        cpuCreditsExec: "Server.CpuCredits.Exec",
        cpuCreditsExecArguments: "Server.CpuCredits.Exec.Arguments",
        cpuCreditsExecSyncInterval: "Server.CpuCredits.Exec.SyncIntervalInMin",
        cpuCreditsExecTimeout: "Server.CpuCredits.Exec.TimeoutInSec",
        threadPoolMinWorkerThreads: "Server.ThreadPool.MinWorkerThreads",
        threadPoolMinCompletionPortThreads: "Server.ThreadPool.MinCompletionPortThreads",
        threadPoolMaxWorkerThreads: "Server.ThreadPool.MaxWorkerThreads",
        threadPoolMaxCompletionPortThreads: "Server.ThreadPool.MaxCompletionPortThreads",
        disableAdminChannel: "Server.AdminChannel.Disable",
        disableLogsStream: "Server.LogsStream.Disable",
        disableTcpCompression: "Server.Tcp.Compression.Disable"
    }
    static sharding = {
        compressionLevel: "Sharding.Import.CompressionLevel",
        shardExecutorUseHttpDecompression: "Sharding.ShardExecutor.UseHttpDecompression",
        shardExecutorUseHttpCompression: "Sharding.ShardExecutor.UseHttpCompression",
        orchestratorTimeout: "Sharding.OrchestratorTimeoutInMin"
    }
    static storage = {
        discardVirtualMemory: "Storage.DiscardVirtualMemory",
        tempPath: "Storage.TempPath",
        forceUsing32BitsPager: "Storage.ForceUsing32BitsPager",
        enablePrefetching: "Storage.EnablePrefetching",
        enableIoMetrics: "Storage.IO.Metrics.Enabled",
        transactionsModeDuration: "Storage.TransactionsModeDurationInMin",
        maxConcurrentFlushes: "Storage.MaxConcurrentFlushes",
        timeToSyncAfterFlush: "Storage.TimeToSyncAfterFlashInSec",
        numberOfConcurrentSyncsPerPhysicalDrive: "Storage.NumberOfConcurrentSyncsPerPhysicalDrive",
        compressTxAboveSize: "Storage.CompressTxAboveSizeInKb",
        maxScratchBufferSize: "Storage.MaxScratchBufferSizeInMb",
        prefetchBatchSize: "Storage.PrefetchBatchSizeInKb",
        prefetchResetThreshold: "Storage.PrefetchResetThresholdInGb",
        freeSpaceAlertThresholdInPercentages: "Storage.FreeSpaceAlertThresholdInPercentages",
        freeSpaceAlertThreshold: "Storage.FreeSpaceAlertThresholdInMb",
        syncJournalsCountThreshold: "Storage.SyncJournalsCountThreshold",
        ioMetricsCleanupInterval: "Storage.IoMetricsCleanupIntervalInHrs",
        onDirectoryInitializeExec: "Storage.OnCreateDirectory.Exec",
        onDirectoryInitializeExecArguments: "Storage.OnCreateDirectory.Exec.Arguments",
        onDirectoryInitializeExecTimeout: "Storage.OnCreateDirectory.Exec.TimeoutInSec",
        ignoreInvalidJournalErrors: "Storage.Dangerous.IgnoreInvalidJournalErrors",
        skipChecksumValidationOnDatabaseLoading: "Storage.Dangerous.SkipChecksumValidationOnDatabaseLoading",
        ignoreDataIntegrityErrorsOfAlreadySyncedTransactions: "Storage.IgnoreDataIntegrityErrorsOfAlreadySyncedTransactions",
        disableEncryptionBuffersPooling: "Storage.Encrypted.DisableBuffersPooling",
        maxNumberOfRecyclableJournals: "Storage.MaxNumberOfRecyclableJournals"
    }
    static studio = {
        path: "Studio.Path"
    }
    static subscriptions = {
        maxNumberOfConcurrentConnections: "Subscriptions.MaxNumberOfConcurrentConnections",
        archivedDataProcessingBehavior: "Subscriptions.ArchivedDataProcessingBehavior"
    }
    static tombstone = {
        cleanupInterval: "Tombstones.CleanupIntervalInMin",
        retentionTimeWithReplicationHub: "Tombstones.RetentionTimeWithReplicationHubInHrs",
        cleanupIntervalWithReplicationHub: "Tombstones.CleanupIntervalWithReplicationHubInMin"
    }
    static trafficWatch = {
        trafficWatchMode: "TrafficWatch.Mode",
        databases: "TrafficWatch.Databases",
        statusCodes: "TrafficWatch.StatusCodes",
        minimumResponseSize: "TrafficWatch.MinimumResponseSizeInBytes",
        minimumRequestSize: "TrafficWatch.MinimumRequestSizeInBytes",
        minimumDuration: "TrafficWatch.MinimumDurationInMs",
        httpMethods: "TrafficWatch.HttpMethods",
        changeTypes: "TrafficWatch.ChangeTypes",
        certificateThumbprints: "TrafficWatch.CertificateThumbprints"
    }
    static transactionMerger = {
        maxTimeToWaitForPreviousTx: "TransactionMerger.MaxTimeToWaitForPreviousTxInMs",
        maxTimeToWaitForPreviousTxBeforeRejecting: "TransactionMerger.MaxTimeToWaitForPreviousTxBeforeRejectingInMs",
        maxTxSize: "TransactionMerger.MaxTxSizeInMb"
    }
    static updates = {
        channel: "Updates.Channel",
        backgroundChecksDisabled: "Updates.BackgroundChecks.Disable"
    }
    static postgreSql = {
        enabled: "Integrations.PostgreSQL.Enabled",
        port: "Integrations.PostgreSQL.Port"
    }
    static openTelemetry = {
        enabled: "Monitoring.OpenTelemetry.Enabled",
        serviceInstanceId: "Monitoring.OpenTelemetry.ServiceInstanceId",
        serverMetersEnabled: "Monitoring.OpenTelemetry.Meters.Server.Enabled",
        aspNetCoreInstrumentationMetersEnabled: "Monitoring.OpenTelemetry.Meters.AspNetCore.Enabled",
        runtimeInstrumentationMetersEnabled: "Monitoring.OpenTelemetry.Meters.Runtime.Enabled",
        openTelemetryProtocolExporter: "Monitoring.OpenTelemetry.OpenTelemetryProtocol.Enabled",
        otlpEndpoint: "Monitoring.OpenTelemetry.OpenTelemetryProtocol.Endpoint",
        otlpProtocol: "Monitoring.OpenTelemetry.OpenTelemetryProtocol.Protocol",
        otlpHeaders: "Monitoring.OpenTelemetry.OpenTelemetryProtocol.Headers",
        otlpExportProcessorType: "Monitoring.OpenTelemetry.OpenTelemetryProtocol.ExportProcessorType",
        otlpTimeout: "Monitoring.OpenTelemetry.OpenTelemetryProtocol.Timeout",
        consoleExporter: "Monitoring.OpenTelemetry.ConsoleExporter",
        serverStorage: "Monitoring.OpenTelemetry.Meters.Server.Storage.Enabled",
        cPUCredits: "Monitoring.OpenTelemetry.Meters.Server.CPUCredits.Enabled",
        resources: "Monitoring.OpenTelemetry.Meters.Server.Resources.Enabled",
        totalDatabases: "Monitoring.OpenTelemetry.Meters.Server.TotalDatabases.Enabled",
        requests: "Monitoring.OpenTelemetry.Meters.Server.Requests.Enabled",
        gcEnabled: "Monitoring.OpenTelemetry.Meters.Server.GC.Enabled",
        generalEnabled: "Monitoring.OpenTelemetry.Meters.Server.General.Enabled"
    }
    static snmp = {
        enabled: "Monitoring.Snmp.Enabled",
        port: "Monitoring.Snmp.Port",
        community: "Monitoring.Snmp.Community",
        authenticationProtocol: "Monitoring.Snmp.AuthenticationProtocol",
        authenticationProtocolSecondary: "Monitoring.Snmp.AuthenticationProtocol.Secondary",
        authenticationUser: "Monitoring.Snmp.AuthenticationUser",
        authenticationUserSecondary: "Monitoring.Snmp.AuthenticationUser.Secondary",
        authenticationPassword: "Monitoring.Snmp.AuthenticationPassword",
        authenticationPasswordSecondary: "Monitoring.Snmp.AuthenticationPassword.Secondary",
        privacyProtocol: "Monitoring.Snmp.PrivacyProtocol",
        privacyProtocolSecondary: "Monitoring.Snmp.PrivacyProtocol.Secondary",
        privacyPassword: "Monitoring.Snmp.PrivacyPassword",
        privacyPasswordSecondary: "Monitoring.Snmp.PrivacyPassword.Secondary",
        supportedVersions: "Monitoring.Snmp.SupportedVersions",
        disableTimeWindowChecks: "Monitoring.Snmp.DisableTimeWindowChecks"
    }
}

export = configurationConstants;
